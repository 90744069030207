<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditSetorDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <SetorDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                             :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import SetorDataTable from "../../components/shared/datatables/SetorDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/setor";
import CreateEditSetorDialog from "../../components/shared/dialogs/CreateEditSetorDialog";
import {ApiError} from "../../api/core";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {CreateEditSetorDialog, BaseView, SetorDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Setor removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover o Setor.`, {
            timeout: 6000
          })
        }
      }
    },
    async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Setor.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>